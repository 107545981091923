<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="material-card  mb-1 p-1">
      <div class="d-flex">
        <div class="mr-1">
          <b-avatar rounded="lg" variant="light-primary">
            <i class="card-icon ti ti-clipboard-list "></i>
          </b-avatar>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column ">
              <b-card-subtitle class="text-muted ">Ujian</b-card-subtitle>
              <b-card-title class="font-weight-bolder small-gap-bottom">
                UAS Geografi</b-card-title
              >
            </div>
          </div>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col md="3">
          <!-- Teacher Info -->
          <div>
            <h6 class="font-weight-bolder small-gap-bottom">
              Guru Kelas
            </h6>
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  src="/img/13-small.d796bffd.png"
                  text="avatarText('Bambang')"
                  variant="`light-primary`"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                Sukmadi, S.Pd.
              </span>
              <small class="text-muted">NIP. 1404591288123</small>
            </b-media>
          </div>

          <!-- Class Info -->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Kelas
            </h6>
            <span>
              Geografi
            </span>
          </div>

          <!-- Material of Exam -->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Kisi Kisi
            </h6>
            <span>
              Pelajari semua latihan soal :
            </span>
            <ul class="regular-ul">
              <li>BAB 1</li>
              <li>BAB 2</li>
              <li>BAB 3</li>
              <li>BAB 4</li>
            </ul>
          </div>
        </b-col>
        <b-col>
          <!-- Exam Alert  -->
          <b-alert show variant="warning">
            <div class="alert-body p-2">
              <div class="d-flex">
                <div class="mr-1">
                  <b-avatar rounded="lg" class="bg-white">
                    <i class="card-icon text-warning ti ti-alert-triangle"></i>
                  </b-avatar>
                </div>
                <div class="d-flex flex-column">
                  <h5 class="font-weight-bolder small-gap-bottom text-warning">
                    Perhatian
                  </h5>
                  <span class="font-weight-normal"
                    >Sugar plum apple pie sesame snaps croissant marshmallow
                    apple pie liquorice. Cheesecake bear claw tiramisu
                    shortbread cupcake. Sugar plum candy canes jujubes liquorice
                    tiramisu gummi bears muffin dragée gingerbread.
                  </span>
                </div>
              </div>
            </div>
          </b-alert>

          <!-- Misc -->
          <div>
            <div class="d-flex justify-content-between small-gap-bottom">
              <h6 class="font-weight-bolder small-gap-bottom">
                Progress
              </h6>
              <h6 class="font-weight-bolder small-gap-bottom">
                2 Jam Waktu Pengerjaan
              </h6>
            </div>
            <b-progress value="2" max="100" />
            <ul class="regular-ul small-gap-top">
              <li>
                Lihat progress bar ini untuk melihat waktu yang sedang berjalan
              </li>
              <li>
                Kerjakan soal yang paling mudah terlebih dahulu, periksa kembali
                jawaban anda.
              </li>
            </ul>
          </div>

          <!-- Exam Step Info -->
          <div>
            <h6 class="font-weight-bolder mt-1 small-gap-bottom">
              Langkah Mengerjakan Ujian
            </h6>
            <ol class="regular-ul">
              <li>Klik “Konfirmasi kesediaan ujian”.</li>
              <li>
                Terdapat 2 model soal yaitu pilihan ganda dan isian.
              </li>
              <li>Pilih salah satu pilihan ganda dan klik lanjut.</li>
              <li>
                Jika masih ragu-ragu terhadap pilihan klik button tandai.
              </li>
              <li>
                Klik tombol “Selesaikan Ujian” di akhir ujian.
              </li>
            </ol>
          </div>
        </b-col>
        <b-col md="12" class="border-top pt-1 mt-1">
          <!-- Accept term and condition -->
          <div class="d-flex justify-content-start small-gap-bottom">
            <b-form-checkbox v-model="isAcceptTerm" value="true">
              Konfirmasi kesediaan mengikuti ujian.
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-start">
            <b-button variant="outline-danger">
              Batal
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              :disabled="!isAcceptTerm"
              :to="{
                name: 'exam-session-start',
                params: { id: examId },
              }"
            >
              <span class="align-middle mr-1">Mulai Ujian</span>
              <i class="align-middle ti ti-arrow-right"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAlert,
  BAvatar,
  BMedia,
  BProgress,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAlert,
    BAvatar,
    BMedia,
    BProgress,
    BFormCheckbox,
  },
  data() {
    return {
      examId: this.$router.currentRoute.params.id,
      isAcceptTerm: false,
      headerData: {
        avatar: '/img/avatar-s-2.da5e73c7.jpg',
        username: 'Budiman S.Pd.',
        coverImg: 'https://picsum.photos/id/10/2500/1667',
      },
    }
  },
  computed: {},
}
</script>

<style lang="scss">
.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.regular-ul {
  padding-left: 1.5rem;
}
</style>
